<template>
  <div class="container">
    <div class="img">
      <img src="../../assets/金隅嘉华大厦.png" alt="" />

    </div>

    <div class="zou"  >
      <div class="han">联系我们</div>
      <div class="ying">
        Contact us
      </div>
    </div>
    <div class="div1">联系我们</div>
    <div class="div2">Contact us</div>
    <wo></wo>
  </div>
</template>

<script>
  import Wo from  "./Wo"
  export default {
    name: "Lian",
    components:{
      Wo
    },
    watch:{
      $route:{
        deep:true,
        immediate:true,
        handler(newV,olV) {
          this.active = newV.name;
        }
      }
    },
  }
</script>

<style scoped>
.container{

}
.img img {
  width: 100%;
  height: 48.6891vh;
}
.zou{
  width: 11.1979vw;
  height: 12.9551vh;
  opacity: 0.8;
  background-color: #d7000f;
  position: absolute;
  margin-left: 5.5625vw;
  margin-top: -8.4633vh;
}
.han{
  width: 10.7083vw;
  height: 1.4063vw;
  font-size: 1.5625vw;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  margin-top:0.6771vw ;
  margin-left: 0.8854vw;
}
.ying{
  width: 10.8125vw;
  height: 1.1979vw;
  font-size: 1.45vw;
  font-family: PingFang SC, PingFang SC-Light;
  font-weight: 300;
  text-align: left;
  color: #ffffff;
  margin-left: 0.8854vw;
  margin-top: 0.6vw;
}
.div1 {
  width: 21.25vw;
  height: 2.0833vw;
  font-size: 2.2917vw;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  color: #d7000f;
  margin-top: 4.1146vw;
  margin-left: 45.5vw;
}
.div2 {
  width: 21.5625vw;
  height: 1.0417vw;
  font-size: 1.3542vw;
  margin-top: 1.0417vw;
  margin-left: 46vw;
  font-family: PingFang SC, PingFang SC-Regular;
  color: #646464;
}
</style>