<template>
  <div>
    <index></index>
    <router-view></router-view>
    <bottom></bottom>
  </div>
</template>

<script>
import Dao from "../components/common/Dao.vue";
import Bottom from "../components/common/Bottom.vue";
import Index from "../components/lianxi/Index";
export default {
  name: "Lian",
  components: {
    Dao,
    Bottom,
    Index,
  },
};
</script>

<style scoped></style>
