<template>
  <div class="container">
    <div class="qq">
      <div class="one">
<de></de>
      </div>
      <div class="right">
        <div class="two">
          <div class="title">{{data.title}}</div>
          <div >
            <div class="xia">地址：{{data.address}}</div>
            <div class="xia">电话：{{data.phone}}</div>
            <div class="xia">传真：{{data.fax}}</div>
            <div class="xia">服务热线：{{data.mobile}}</div>
            <div class="xia">邮箱：{{data.email}}</div>
            <div class="xia">邮编：{{data.zipcode}}</div>
          </div>
        </div>
        <div class="three">
          <img :src="data.pic" alt="">
          <div>了解更多大伟嘉动态</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import De from "./De"
  import {getContent} from "../../api/lianxiwomen"
  export default {
    name: "Wo",
    components:{
      De
    },
    data() {
      return {
        data: [],
      };
    },
    mounted() {
      getContent().then((res) => {
        console.log(res.data);
        let data = res.data;
        // console.log(data);
        this.data = data;
      });
    },
  }
</script>

<style scoped>
  .container{
    margin-top: 5vw;
    height: 80vh;
  }
.qq{
  display: flex;
}
  .right{
    position: absolute;
    margin-left: 58vw;
  }
  .title{
    width: 35vw;
    height: 3vw;
    font-size: 1.7vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #000000;
    margin-bottom: 1.3vw;
  }
  .two{
    margin-bottom: 2.2vw;
  }
  .xia{
    margin-bottom: 0.4vw;
    font-size: 1vw;
  }

img{
  width: 10vw;
  height: 10vw;
}
  .three div{
    width: 20vw;
    height: 1.7vw;
    font-size: 0.9vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    margin-top: 1.5vw;
  }
</style>