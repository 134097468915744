<template>
  <div id="container"></div>
</template>
<script>
  export default {
    name: "Mapview",
    data() {
      return {};
    },
    created() {},
    mounted() {
      this.initAMap();
    },
    methods: {
      initAMap() {
        // let img =require("./img/4.png");
        // var map = new AMap.Map("container", {
        //   viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D',
        //   zoom: 301, //初始化地图层级
        //   center: [116.397428, 39.90923], //初始化地图中心点
        //  icon:new AMap.Icon({
        //    image: img,
        //  }),
        // });
        //
        // var marker = new AMap.Marker();
        // marker.setMap(map);
        var map = new AMap.Map('container', {
          zoom:300,//级别仔细度
          center: [116.3073, 40.0366],//中心点坐标
          viewMode:'3D'//使用3D视图
        });
    let icon = require("./img/4.png")
        var marker = new AMap.Marker({
          // position: new AMap.LngLat(116.39,39.9),
          // offset: new AMap.Pixel(-10, -10),
          icon: icon,
          title: '北京'
        });

        map.add(marker);


      },
    },
  };
</script>
<style scoped lang="less">
  #container {
    margin-left: 12vw;
    width: 42vw;
    height: 27vw;
  }

</style>
